<template>
  <div class="intro-container">
    <audio id="click" class="hidden">
      <source src="../assets/sounds/partial.wav" type="audio/wav">
    </audio>
    <div class="animate__animated animate__fadeOut animate__fast animate__delay-1s">
      <svg width="250px" height="350px" viewBox="0 0 250 350" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="slide" class="logo-top">
          <circle class="pure-white" cx="126.01" cy="205.99" r="61.21"/>
          <path class="dark-grey" d="M134.27,154.76v11.4c0,1.42-1.15,2.56-2.56,2.56h-11.4c-1.42,0-2.56-1.15-2.56-2.56v-11.4
			c0-1.42,1.15-2.56,2.56-2.56h11.4C133.12,152.2,134.27,153.34,134.27,154.76z"/>
          <path class="dark-grey" d="M158.9,163.6l-4.31,10.55c-0.54,1.31-2.03,1.94-3.34,1.4l-10.55-4.31c-1.31-0.54-1.94-2.03-1.4-3.34
			l4.31-10.55c0.54-1.31,2.03-1.94,3.34-1.4l10.55,4.31C158.81,160.79,159.44,162.29,158.9,163.6z"/>
          <path class="dark-grey" d="M134.27,244.97v11.4c0,1.42-1.15,2.56-2.56,2.56h-11.4c-1.42,0-2.56-1.15-2.56-2.56v-11.4
			c0-1.42,1.15-2.56,2.56-2.56h11.4C133.12,242.41,134.27,243.56,134.27,244.97z"/>
          <path class="dark-grey" d="M88.35,200.3v11.4c0,1.42-1.15,2.56-2.56,2.56h-11.4c-1.42,0-2.56-1.15-2.56-2.56v-11.4
			c0-1.42,1.15-2.56,2.56-2.56h11.4C87.2,197.73,88.35,198.88,88.35,200.3z"/>
          <path class="dark-grey" d="M178.45,200.3v11.4c0,1.42-1.15,2.56-2.56,2.56h-11.4c-1.42,0-2.56-1.15-2.56-2.56v-11.4
			c0-1.42,1.15-2.56,2.56-2.56h11.4C177.31,197.73,178.45,198.88,178.45,200.3z"/>
          <path class="dark-grey" d="M170.99,174.6l4.56,10.45c0.57,1.3-0.03,2.81-1.32,3.37l-10.45,4.56c-1.3,0.57-2.81-0.03-3.37-1.32
			l-4.56-10.45c-0.57-1.3,0.03-2.81,1.32-3.37l10.45-4.56C168.91,172.71,170.42,173.3,170.99,174.6z"/>
          <path class="dark-grey" d="M96.16,174.53l-4.31-10.55c-0.54-1.31,0.09-2.81,1.4-3.34l10.55-4.31c1.31-0.54,2.81,0.09,3.34,1.4
			l4.31,10.55c0.54,1.31-0.09,2.81-1.4,3.34l-10.55,4.31C98.19,176.46,96.7,175.84,96.16,174.53z"/>
          <path class="dark-grey" d="M75.24,185.43l4.56-10.45c0.57-1.3,2.08-1.89,3.37-1.32l10.45,4.56c1.3,0.57,1.89,2.08,1.32,3.37l-4.56,10.45
			c-0.57,1.3-2.08,1.89-3.37,1.32l-10.45-4.56C75.27,188.24,74.68,186.73,75.24,185.43z"/>
          <path class="dark-grey" d="M93.43,247.92l4.31-10.55c0.54-1.31,2.03-1.94,3.34-1.4l10.55,4.31c1.31,0.54,1.94,2.03,1.4,3.34l-4.31,10.55
			c-0.54,1.31-2.03,1.94-3.34,1.4l-10.55-4.31C93.52,250.72,92.89,249.23,93.43,247.92z"/>
          <path class="dark-grey" d="M81.34,236.92l-4.56-10.45c-0.57-1.3,0.03-2.81,1.32-3.37l10.45-4.56c1.3-0.57,2.81,0.03,3.37,1.32
			l4.56,10.45c0.57,1.3-0.03,2.81-1.32,3.37l-10.45,4.56C83.42,238.81,81.91,238.21,81.34,236.92z"/>
          <path class="dark-grey" d="M154.29,237.9l4.31,10.55c0.54,1.31-0.09,2.81-1.4,3.34l-10.55,4.31c-1.31,0.54-2.81-0.09-3.34-1.4
			l-4.31-10.55c-0.54-1.31,0.09-2.81,1.4-3.34l10.55-4.31C152.25,235.96,153.75,236.58,154.29,237.9z"/>
          <path class="dark-grey" d="M175.22,226.99l-4.56,10.45c-0.57,1.3-2.08,1.89-3.37,1.32l-10.45-4.56c-1.3-0.57-1.89-2.08-1.32-3.37
			l4.56-10.45c0.57-1.3,2.08-1.89,3.37-1.32l10.45,4.56C175.19,224.18,175.78,225.69,175.22,226.99z"/>
          <g id="logo">
            <text transform="matrix(1 0 0 1 115.1714 211.9892)" class="red dark-grey-stroke very-small-stroke">sj</text>
            <text transform="matrix(1 0 0 1 116.3432 210.5605)" class="yellow dark-grey-stroke very-small-stroke">sj</text>
            <text transform="matrix(1 0 0 1 117.7964 208.9052)" class="blue dark-grey-stroke very-small-stroke">sj</text>
            <text transform="matrix(1 0 0 1 119.3076 207.1357)" class="white dark-grey-stroke very-small-stroke">sj</text>
          </g>
        </g>
        <g id="viewmaster" class="logo-bottom">
          <g>
            <path class="yellow" d="M212.43,184.86c2.66,6.89,8.95,11.97,16.53,12.9c-2.38,2.14-5.03,3.61-7.25,4.59
					c-2.78-2.16-6.23-3.52-10.01-3.68c-0.61-0.08-1.22-0.14-1.83-0.21l-0.64-12.25C210.33,185.81,211.39,185.36,212.43,184.86z"/>
            <path class="yellow" d="M213.71,177.53L213.71,177.53c0-9.79,7.94-17.73,17.73-17.73s17.73,7.94,17.73,17.73s-7.94,17.73-17.73,17.73
					C221.66,195.26,213.73,187.32,213.71,177.53z M231.45,171.37c-3.41-0.01-6.18,2.75-6.18,6.16c0,3.4,2.75,6.15,6.14,6.16
					c3.4,0.01,6.17-2.74,6.18-6.14C237.6,174.15,234.85,171.38,231.45,171.37L231.45,171.37z"/>
            <path class="dark-yellow" d="M217.81,177.53C217.81,177.53,217.81,177.53,217.81,177.53c0-7.53,6.11-13.64,13.64-13.64
					s13.64,6.11,13.64,13.64c0,7.53-6.11,13.64-13.64,13.64C223.92,191.17,217.81,185.06,217.81,177.53z M221.2,177.53
					c0,0.01,0,0.01,0,0.02c0.01,5.67,4.62,10.26,10.29,10.25c5.67-0.01,10.26-4.62,10.25-10.29c-0.01-5.67-4.62-10.26-10.29-10.25
					C225.79,167.28,221.2,171.87,221.2,177.53z"/>
            <circle class="yellow" cx="231.45" cy="177.53" r="11.95"/>
          </g>
          <polygon class="viewmaster-filler"
                   points="57.3,188.62 63.97,270.96 188.66,270.96 197.87,188.62 		"/>
          <path class="red" d="M44.2,198.19c0.21-4.59,0.58-12.6,0.88-18.43c0.46-8.64,4.42-11.11,6.96-12.67
			c2.54-1.56,17.14-0.91,28.13-0.91c11.95,7.02,28.25,9.14,45.84,9.75c17.59-0.64,33.89-2.73,45.83-9.75c11,0,25.6-0.64,28.14,0.91
			c2.54,1.55,6.5,4.06,6.95,12.67c0.3,5.81,0.66,13.83,0.86,18.43C153.45,191.72,98.54,191.72,44.2,198.19z"/>
          <path class="red" d="M207.85,256.46c-0.89,14.56-6.66,47.82-8.56,52.07c-2.08,4.67-8.12,5.2-8.12,5.2H60.86
			c0,0-6.09-0.52-8.12-5.2c-1.85-4.26-7.66-37.51-8.57-52.06c27.09,3.22,54.34,4.85,81.63,4.84
			C153.22,261.3,180.62,259.67,207.85,256.46z"/>
          <path class="red" d="M25.89,216.15v22.34c0.01,8.14,6.46,14.82,14.59,15.11c28.3,3.53,56.79,5.29,85.31,5.29
			c28.66,0,57.29-1.77,85.73-5.29c8.14-0.29,14.59-6.97,14.59-15.11v-22.34c-0.01-8.14-6.46-14.81-14.59-15.1
			c-56.79-7.11-114.24-7.11-171.03,0C32.35,201.33,25.9,208.01,25.89,216.15z M33.16,216.16c0.02-4.35,3.54-7.87,7.89-7.88
			c56.44-7.13,113.55-7.13,169.99,0c4.33,0.03,7.83,3.54,7.85,7.87v22.34c-0.02,4.35-3.54,7.87-7.89,7.89
			c-56.44,7.08-113.55,7.08-169.99,0c-4.33-0.04-7.83-3.55-7.85-7.88V216.16z"/>
          <path id="_x3C_Path_x3E_" class="dark-grey" d="M41.06,208.28c56.44-7.13,113.55-7.13,169.99,0c4.33,0.03,7.83,3.54,7.85,7.87v22.34
			c-0.02,4.35-3.54,7.87-7.89,7.89c-56.44,7.08-113.55,7.08-169.99,0c-4.33-0.04-7.83-3.55-7.85-7.88v-22.34
			C33.18,211.81,36.71,208.29,41.06,208.28z"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  mounted() {
    // check local storage from source rather than prop bc prop is undefined at this point
    setTimeout(function () {
      if(localStorage.sound === 'true'){
        let click = document.getElementById("click");
        click.play();
      }
    }, 250);
    setTimeout(()=> {
      this.$emit('hide')
    }, 1250);
  }
}
</script>